import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialLinks from "../components/socialLinks"
import Projects from "../components/projects"
import Clients from "../components/clients"
import GetInTouch from "../components/getInTouch"

const IndexPage = ({ data }) => {
  const {pageData, svgFiles} = data;

  return (
    <Layout headerData={pageData.siteMetadata.pages.home}>
      <Link to="/music" className="ribbon slant-down">
        <div className="content">
          Music →
        </div>
      </Link>

      <SEO
        title={pageData.siteMetadata.pages.home.title}
        description={pageData.siteMetadata.pages.home.description}
      />
      <SocialLinks svgFiles={svgFiles} links={pageData.siteMetadata.dev.links}></SocialLinks>
      <hr/>
      <Projects
        title='Personal Projects'
        projects={pageData.siteMetadata.dev.projects.filter(p => p.type === "personal")}
      />
      <hr/>
      <Projects
        title='Client Projects'
        projects={pageData.siteMetadata.dev.projects.filter(p => p.type === "client")}
      />
      <hr/>

      <Clients></Clients>
      <hr/>
      <GetInTouch></GetInTouch>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    pageData: site {
      siteMetadata {
        pages {
          home {
            title
            name
            description
          }
        }
        dev {
          projects {
            name
            tld
            type
            url
            description
            techStack
            image
          }
          links {
            name
            url
            filename
            tooltip
            icon
          }
        }
      }
    }
    svgFiles: allFile(filter: { extension: { eq: "svg" }}) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default IndexPage;