import React from "react"
import "./clients.css";
import { useStaticQuery, graphql } from "gatsby";

const Clients = (props) => {

  const data = useStaticQuery(graphql`
    query ClientsQuery {
      site {
        siteMetadata {
          dev {
            clients {
              name
              url
            }
          }
        }
      }
    }
  `)

  const clients = data.site.siteMetadata.dev.clients;
  function renderDash(i) {
    if ((i+1) < clients.length) {
      return (
        <span className="dull"> - </span>
      );
    }
    return ``;
  }

  return (
    <>
      <h2>Clients</h2>
      <div className="clients" style={{
        display: `flex`,
        flexWrap: `wrap`
      }}>
        {clients
          .map((client, i) => {
          return (
            <a key={i} className="client" title={client.name} target="_blank" rel="noopener noreferrer" href={client.url}>
              <span>{client.name}</span>{renderDash(i)}
            </a>
          )
        })}
      </div>
    </>
  )
}
export default Clients;