import React from "react"
import "./projects.css";

const Projects = (props) => {
  const { title, projects } = props;

  return (
    <div className="projects">
      <h2>{title}</h2>
      {projects
        .map((project, i) => {
        if (project.description) {
          return (
            <div key={i} className="project">
              <h3>
                <a title={project.name} target="_blank" rel="noopener noreferrer" href={project.url}>
                  <span className="w-name">{project.name}</span>
                  {/* <span className="w-tld">{project.tld}</span> */}
                </a>
              </h3>
              <p>
                <span>{project.description}</span><br/>
                <span className="tech">{project.techStack.join(' | ')}</span>
              </p>
            </div>
          )
        }

        return <React.Fragment key={i} />
      })}
    </div>
  )
}

export default Projects;
